import PrivacyPolicies from '../../components/PrivacyPolicies/PrivacyPolicies';
import { Container } from '../../components/Container';
import { Link } from 'react-router-dom';
import styles from './PrivacyPoliciesPage.module.scss';
import { Footer } from '../../components/Footer';

function PrivacyPoliciesPage() {
  return (
    <>
      <Container>
        <div className={styles.logo}>
          <Link to={'/'}>
            <img src="./images/logo.svg" alt="Kebeta" className="Logo" />
          </Link>
        </div>
        <PrivacyPolicies />
      </Container>
      <Footer />
    </>
  );
}

export default PrivacyPoliciesPage;

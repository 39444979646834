import './App.scss';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/HomePage';
import PrivacyPoliciesPage from './pages/PrivacyPoliciesPage/PrivacyPoliciesPage';

export const App = () => (
  <Routes>
    <Route path="*" element={<Home />} />
    <Route path="/privacy-policies" element={<PrivacyPoliciesPage />} />
  </Routes>
);

import {
  cookieNotice,
  privacyPolicies,
  termsOfUse,
} from '../../helpers/privacyPolicies';
import styles from './PrivacyPolicies.module.scss';

import { PropTypes } from 'prop-types';

const PoliciesSection = ({ title, list }) => (
  <>
    <h1>{title}</h1>
    {list.map(({ title, content }) => (
      <div key={title} className={styles.section}>
        {title && <h2>{title}</h2>}
        {content.map((text, index) => (
          <p key={index}>{text}</p>
        ))}
      </div>
    ))}
  </>
);

PoliciesSection.propTypes = {
  title: PropTypes.string,
  list: PropTypes.array,
};

function PrivacyPolicies() {
  return (
    <div className={styles.container}>
      <PoliciesSection list={privacyPolicies} title={'Privacy Policies'} />
      <PoliciesSection list={cookieNotice} title={'Cookie Notice'} />
      <PoliciesSection list={termsOfUse} title={'Terms of Use'} />
    </div>
  );
}

export default PrivacyPolicies;

import { useState } from 'react';
import './Form.scss';
import { Loader } from '../Loader';

export const Form = () => {
  const [isSending, setIsSending] = useState(false);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');

  const checkEmail = () => {
    return !email.includes('@');
  };

  const checkPhone = () => {
    return phone.match(/[a-zа-я]/i);
  };

  const clearForm = () => {
    setEmail('');
    setName('');
    setPhone('');
  };

  const sendEmail = (e) => {
    e.preventDefault();


    if (!checkEmail() && !checkPhone() && name.length > 0) {
      setIsSending(true);
      clearForm();

      const message = `Email: ${email}\nName: ${name}\nPhone: ${phone}`;

      fetch('https://formsubmit.co/ajax/hello@kebeta.tech', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          name: 'Kebeta.tech',
          message: message,
        }),
      })
        .finally(() => setIsSending(false));
    }
  };

  return (
    <form className="Form" onSubmit={sendEmail}>
      <h2 className="Form__Title">Contact Us</h2>

      <label className="Form__Label">
        Email
        <input 
          type="text" 
          className="Form__Input" 
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={{ borderColor: checkEmail() && email.length > 0 ? '#FF2257' : '#cecece' }}
        />
        {checkEmail() && email.length > 0 && (<p className="Form__Incorrect">Invalid Email</p>)}
      </label>

      <label className="Form__Label">
        Name
        <input 
          type="text" 
          className="Form__Input" 
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </label>

      <label className="Form__Label">
        Phone
        <input 
          type="text" 
          className="Form__Input" 
          placeholder="Phone"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          style={{ borderColor: checkPhone() && phone.length > 0 ? '#FF2257' : '#cecece' }}
        />
        {checkPhone() && phone.length > 0 && (<p className="Form__Incorrect">Invalid Phone</p>)} 
      </label>

      <button className="Form__Button">
        {isSending
          ? <Loader />
          : ('SCHEDULE A CALL')}
      </button>

      <h4 className="Form__Terms">
        By clicking the button, you agree to our Terms of Service
      </h4>
    </form>
  );
};

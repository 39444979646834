import './About.scss';

export const About = () => (
  <section className="About">
    <div className="About__Image--Wrapper">
      <img src="./images/team.png" alt="laptop" className="About__Image" />
    </div>

    <div className="About__Text">
      <h2 className="About__Title">The Team</h2>
      <p className="About__Description">
        With over 20 years of experience in serial entrepreneurship and
        investing, our experts provide hands-on proficiency and expertise in all
        aspects of the development and growth of startups.
      </p>
      <p className="About__Description">
        We have PhDs on the team, and as a result, all our solutions are based
        on cutting-edge research and proven in practice. Our team is complete
        with the best people on the market who can take your start-up to new
        heights.
      </p>
      <p className="About__Description">
        Because capital investment is part of business strategy, it is important
        for us to invest our knowledge and skills first.
      </p>
    </div>
  </section>
);

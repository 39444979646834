import './JoinSection.scss';

export const JoinSection = () => {
  return (
    <section className="JoinSection">
      <div>
        <h1 className="JoinSection__Title">
          Come and build the future with us!
        </h1>
        <p className="JoinSection__Description">
          Whether you have a high-tech idea or need, we can help. We invest
          funds and our expertise.
          <br />
          <br />
          <span className="JoinSection__Description--Bold">Explore</span> the advantages of deep technology and
          Artificial Intelligence.
        </p>
        <button className="JoinSection__Button">
          <a href="#contacts">SCHEDULE A CALL</a>
        </button>
      </div>

      <div className="JoinSection__Pointing-Wrapper">
        <img
          src="./images/pointing.png"
          alt="pointing"
          className="JoinSection__Pointing"
        />
      </div>
    </section>
  );
};

import { Link } from 'react-router-dom';
import { Container } from '../Container';
import './Logo.scss';

export const Logo = () => (
  <div className="Logo__Wrapper">
    <Container>
      <Link to={'/'}>
        <img src="./images/logo.svg" alt="Kebeta" className="Logo" />
      </Link>
    </Container>
  </div>
);

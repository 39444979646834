import './Policies.scss';

export const Policies = () => (
  <section className="Policies">
    <h2 className="Policies__Title">Investment opportunities</h2>

    <div className="Policies__Frames">
      <article className="Policies__Frame">
        <div className="Policies__Image--Wrapper">
          <img src="./images/book.png" alt="book" className="Policies__Image" />
        </div>
        <div className="Policies__Text">
          <h3 className="Policies__Frame-Title">
            We invest our knowledge first
          </h3>
          <p className="Policies__Description">
            According to our philosophy, knowledge and expertise are the primary
            drivers of a business&#39;s successful growth. People who work on
            early-stage startups frequently simply lack the necessary expertise
            in all the fields required for the development. Experience and
            knowledge of our experts, as well as funds, will help your
            company&#39;s growth.
          </p>
        </div>
      </article>

      <article className="Policies__Frame">
        <div className="Policies__Image--Wrapper">
          <img src="./images/hand.png" alt="book" className="Policies__Image" />
        </div>
        <div className="Policies__Text">
          <h3 className="Policies__Frame-Title">
            Our goal is to focus on quality, not quantity
          </h3>
          <p className="Policies__Description">
            We invest in early-stage startups. The key factor here is our
            ability to dedicate ourselves fully to each of our investments.
          </p>
        </div>
      </article>

      <article className="Policies__Frame">
        <div className="Policies__Image--Wrapper">
          <img
            src="./images/table.png"
            alt="book"
            className="Policies__Image"
          />
        </div>
        <div className="Policies__Text">
          <h3 className="Policies__Frame-Title">
            We&#39;d like to understand your business
          </h3>
          <p className="Policies__Description">
            Building a company is a long journey that requires strength of
            character to make challenging and sometimes unpredictable decisions.
            We have been down this road many times and have a complete
            understanding of the difficulties founders face.
          </p>
        </div>
      </article>
    </div>
  </section>
);

import { memo } from 'react';
import PropTypes from 'prop-types';
import './Container.scss';

export const Container = memo(({ children }) => (
  <div className="Container">{children}</div>
));

Container.propTypes = {
  children: PropTypes.node,
};

import './Contact.scss';
import { Form } from '../Form';

export const Contact = () => (
  <section className="Contact">
    <div>
      <h2 className="Contact__Title">Let&#39;s talk asap</h2>

      <h4 className="Contact__Description">
        We&apos;d love to tell you more about our products and service. Please fill
        in your contact details and we will be in touch with you within 24 hours
      </h4>

      <article className="Contact__Links">
        <ul className="Contact__List">
          <li className="Contact__Item">
            <img src="./images/phone.svg" alt="phone" />
            <a href="tel:0035722029643" className="Contact__Link">
              0035722029643
            </a>
          </li>

          <li className="Contact__Item">
            <img src="./images/mail.svg" alt="mail" />
            <a href="mailto:hello@kebeta.tech" className="Contact__Link">
              hello@kebeta.tech
            </a>
          </li>

          <li className="Contact__Item">
            <img src="./images/map.svg" alt="map" className="Contact__Map" />
            <p className="Contact__Link">
              95 Archbishop Makarios III
              <br />
              Charitini Building, 1st floor, office 102
              <br />
              Nicosia 1071
              <br />
              Cyprus
            </p>
          </li>
        </ul>
      </article>
    </div>

    <div className="Contact__Form" id="contacts">
      <Form />
    </div>
  </section>
);

import './Services.scss';

export const Services = () => (
  <section className="Services">
    <div className="Services__Top">
      <h2 className="Services__Title">Our AI services</h2>

      <h4 className="Services__Subtitle">
        With the increased interest in developing AI-driven solution, the
        majority of tech-driven organizations has discovered that Artificial
        Intelligence can both reduce costs and boost profits. The recent reports
        claim 55% growth from AI initiatives.
        <br />
        <br />
        Through specialized research and development, our qualified team will
        provide a customized AI solution for your unique needs. No public and
        all-purpose tools, ready APIs. Your problem - our solution!
      </h4>
    </div>

    <div className="Services__Frames">
      <div className="Services__Group">
        <article className="Services__Frame">
          <img
            src="./images/frame01.svg"
            alt="brain"
            className="Services__Frame--Icon"
          />
          <h5 className="Services__Frame--Title">
            Natural Language Understanding
          </h5>

          <p className="Services__Frame--Description">
            Mechanism of Natural Language Understanding based on Semantic
            Analysis. It is a method to extract useful information, such as
            context or emotions for understanding the meaning of a certain text
            or any other kind of data, whether oral or written.
          </p>
        </article>

        <article className="Services__Frame">
          <img
            src="./images/frame02.svg"
            alt="brain"
            className="Services__Frame--Icon"
          />
          <h5 className="Services__Frame--Title">
            Knowledge graphs & Ontologies
          </h5>

          <p className="Services__Frame--Description">
            Alternative data-based knowledge graphs allow the discovery of deep
            correlations between entities.
          </p>
        </article>

        <article className="Services__Frame Services__Frame--Tablet">
          <img
            src="./images/frame06.svg"
            alt="brain"
            className="Services__Frame--Icon"
          />
          <h5 className="Services__Frame--Title">Behavior analysis</h5>

          <p className="Services__Frame--Description">
            Numerous consumer behavior data, including sales, visits, and
            preferences, are open to your company. It&#39;s time to transform it
            into insightful marketing data.
          </p>
        </article>
      </div>

      <div className="Services__Group Services__Group--Reverse">
        <article className="Services__Frame">
          <img
            src="./images/frame03.svg"
            alt="brain"
            className="Services__Frame--Icon"
          />
          <h5 className="Services__Frame--Title">Computer Vision</h5>

          <p className="Services__Frame--Description">
            Images and video are key factors of inbound and outbound digital
            assets. We will work with your company to develop a pipeline for
            automated analysis of this kind of data.
          </p>
        </article>

        <article className="Services__Frame">
          <img
            src="./images/frame04.svg"
            alt="brain"
            className="Services__Frame--Icon"
          />
          <h5 className="Services__Frame--Title">
            Image generation & processing
          </h5>

          <p className="Services__Frame--Description">
            What about generating cats? Everyone loves cats! Let us do it for
            your business.
          </p>
        </article>

        <article className="Services__Frame Services__Frame--Tablet">
          <img
            src="./images/frame05.svg"
            alt="brain"
            className="Services__Frame--Icon"
          />
          <h5 className="Services__Frame--Title">
            Deep Reinforcement Learning
          </h5>

          <p className="Services__Frame--Description">
            By taking into account quickly changing conditions and dealing with
            edge and unforeseen scenarios, the exploratory nature of the
            algorithms behind our products and services guarantees that they
            continuously adapt to give considerably safer strategies and
            judgments.
          </p>
        </article>
      </div>

      <div className="Services__Group Services__Group--Reverse">
        <article className="Services__Frame">
          <img
            src="./images/frame05.svg"
            alt="brain"
            className="Services__Frame--Icon"
          />
          <h5 className="Services__Frame--Title">
            Deep Reinforcement Learning
          </h5>

          <p className="Services__Frame--Description">
            By taking into account quickly changing conditions and dealing with
            edge and unforeseen scenarios, the exploratory nature of the
            algorithms behind our products and services guarantees that they
            continuously adapt to give considerably safer strategies and
            judgments.
          </p>
        </article>

        <article className="Services__Frame">
          <img
            src="./images/frame06.svg"
            alt="brain"
            className="Services__Frame--Icon"
          />
          <h5 className="Services__Frame--Title">Behavior analysis</h5>

          <p className="Services__Frame--Description">
            Numerous consumer behavior data, including sales, visits, and
            preferences, are open to your company. It&#39;s time to transform it
            into insightful marketing data.
          </p>
        </article>
      </div>
    </div>
  </section>
);

import { About } from '../../components/About';
import { Contact } from '../../components/Contact';
import { Container } from '../../components/Container';
import { Footer } from '../../components/Footer';
import { JoinSection } from '../../components/JoinSection';
import { Logo } from '../../components/Logo';
import { Policies } from '../../components/Policies';
import { Services } from '../../components/Services';

function Home() {
  return (
    <>
      <div>
        <div className="App__Figure App__Figure--1" />
        <Logo />
        <Container>
          <JoinSection />
        </Container>
      </div>

      <div style={{ position: 'relative' }}>
        <div className="App__Figure App__Figure--2" />
        <Container>
          <About />
          <Services />
        </Container>
      </div>

      <div style={{ position: 'relative' }}>
        <div className="App__Figure App__Figure--3" />
        <Container>
          <Policies />
          <Contact />
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default Home;

import { createRoot } from 'react-dom/client';
import { App } from './App';
import './styles/main.scss';
import { BrowserRouter } from 'react-router-dom';

const Root = () => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

createRoot(document.getElementById('root')).render(<Root />);

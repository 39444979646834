import { Link } from 'react-router-dom';
import './Footer.scss';

export const Footer = () => {
  return (
    <footer className="Footer">
      <div className="Footer__Top">
        <div className="Footer__Logo-Section">
          <a href="#">
            <img src="./images/logo.svg" alt="logo" className="Footer__Logo" />
          </a>
          <p>
            With over 20 years of experience in the IT-industry, we are a
            leading provider of innovative solutions for investors.
          </p>
        </div>
        {/* <div className="Footer__Logo-Section">
          <a href="https://alphagrail.ai" target="_blank" rel="noreferrer">
            <img
              src="./images/alpha-grail-footer.svg"
              alt="logo"
              className="Footer__Logo"
            />
          </a>
          <p>
            Kebeta’s revolutionary investment tool that is developed to provide
            investors with quick and accurate insights
          </p>
        </div> */}
      </div>

      <Link to={'/privacy-policies'} className="Footer__Policies">
        Privacy Policies
      </Link>

      <h5 className="Footer__Copyright">Copyright © 2023</h5>
    </footer>
  );
};

export const privacyPolicies = [
  {
    title: 'Welcome!',
    content: [
      'Kebeta Invest Limited, in compliance with privacy regulations, focuses on the research, development, and personalized customization of AI solutions, as well as the provision of capital and expertise for investments. Kebeta Invest Limited represents AlphaGrail.ai and its other products and services.',
      'This Privacy Policy explains what Personal Information (as defined below) we collect, why we collect it, how we use and disclose it, your choices, any rights you may have, and how you can contact us about our privacy practices. By continuing to use Kebeta Invest Limited products, you agree to this Privacy Policy.',
      'Your privacy matters to us, so whether you are a new client of Kebeta or a long-time customer, please take the time to get to know and familiarize yourself with our policies and practices.',
    ],
  },
  {
    title: 'How We Use Your Personal Information',
    content: [
      'The Personal Information we collect, and the practices described above are done in an effort to provide you with the best experience possible, protect you from risks related to improper use and fraud. We may use your Personal Information to:',
      'A. Provide you with our services. Kebeta collects personal information in case of parties entering into a contractual agreement with us as part of our commitment to providing services.',
      'B. Protect the security and integrity of our services. We use your Personal Information to further our security-first mentality maintaining the security of your account.',
      'C. Optimize and enhance our services. We use your Personal Information to understand how our products and Services are being used to help us improve our Services and develop new products.',
      'D. Market our products to you. We may contact you with information about our products and services that we believe may be of interest to you. You may use tools that we provide to opt out of marketing communications from us at any time.',
      'F. With your consent. We may use your Personal Information for additional purposes with your consent.',
      'G. Other business purposes. We may use your Personal Information for additional purposes in the operation of our business, that would be reasonably expected based on context, and as permitted by law or required to comply with our legal obligations.',
    ],
  },
  {
    title: 'Information For California Residents',
    content: [
      'We are required under the California Consumer Privacy Act of 2018 (“CCPA”) to provide certain information to California residents about how we collect, use and share their personal information, and about the rights and choices California residents may have concerning their personal information. For purposes of this section, “personal information” has the meaning provided in the CCPA.',
      'Right to Request Access. You may submit a request for disclosure of the categories of personal information we have collected about you, the categories of sources from which your personal information is collected, the business or commercial purpose for collecting your personal information, the categories of third parties with whom we share your personal information, and the specific pieces of personal information we have collected about you in the past 12 months.',
      'Right to Request Deletion. You may submit a request that Kebeta delete the personal information about you that we have collected from you.',
      'Right Not to Receive Discriminatory Treatment. You have the right to exercise the privacy rights conferred by the CCPA without discriminatory treatment.',
      'You may submit a request to access or delete your personal information by emailing us to hello@kebeta.tech. We may request additional information from you, like your date of birth or a personal identification number, to verify your identity. If we are unable to verify your identity, we may deny your request. If you submit a request to delete your personal information, you will also be required to confirm the deletion request by email.',
      'You may designate an authorized agent to make a request under the CCPA on your behalf. Your authorized agent must submit proof that you have provided them with power of attorney pursuant to Probate Code sections 4000 to 4465. We may deny a request from a purported authorized agent who does not provide proof of authorization to act on your behalf.',
      'Please understand that we are not obligated to provide you with access to your personal information more than twice in a 12-month period.',
      'If your request is manifestly unfounded or excessive, we may either charge a reasonable fee or refuse to act on the request, in which case we will notify you of the reason for refusing the request.',
    ],
  },
  {
    title: 'Updates To this Privacy Policy',
    content: [
      'We may change this Privacy Policy from time to time to reflect changes to our privacy practices, enhance user experience, or comply with relevant laws. The “Last updated” legend at the top of this Privacy Policy indicates when this Privacy Policy was last revised. Any changes are effective when we post the revised Privacy Policy. Your continued use of Kebeta after our posting of changes to this Privacy Policy means that you understand and agree to such changes',
    ],
  },
  {
    title: 'Contact Us',
    content: [
      'If you have questions or concerns regarding this policy or our processing of your Personal Information, please feel free to email us to hello@kebeta.tech; or write to us at KEBETA INVEST LIMITED: 95 Archbishop Makarios III, Charitini Building, 1st floor, office 102 Nicosia 1071 Cyprus',
    ],
  },
];

export const cookieNotice = [
  {
    title: 'WHY DOES KEBETA USE COOKIES?',
    content: [
      'We use cookies for different purposes, including personalizing your online experience, tracking your interaction with our website, and remembering information about your visit.',
      'The cookies we use are safe. They are neither viruses or malware, and they cannot access the data on your hard drive or see what is on your device. Many cookies are used only to provide key security features and to assist in the protection of your data and accounts.',
      'We set cookies on your computer or another device when you visit our site and use the services of companies we represent. These tools assist us in better understanding user behavior and tell us which sections of our website users have visited the most. The Kebeta team is working hard to ensure that you are satisfied with the services and information we deliver.',
    ],
  },
  {
    title: 'WHAT KIND OF COOKIES DOES KEBETA USE?',
    content: [
      'We use first and third-party cookies to identify you as a Kebeta client. Such cookies types aid us in collecting the data needed to prevent fraud risks and adapt our services and offers to specific user groups. Our mission is to foster trust and safety.',
      'We use third-party cookies to deliver marketing communications and other personalized content that we believe may be of interest to you on this or other websites. We have no control over the information collected and processed by third-party cookies. Please visit the website of the third party to learn more about how they use cookies.',
    ],
  },
  {
    title: 'WHAT KIND OF INFORMATION DO WE COLLECT?',
    content: [
      'We may collect information about customer activity on our website and platforms to see which areas of our Site are most engaging. Because this data is aggregated, it is considered non-personal information.',
      'Cookies provide us with usage metrics and statistical information such as preferred language, inferred zip code or area code, unique device identifier, referrer URL, location, and time zone. This information will not be associated with your IP address. It allows us to improve our Services by learning more about how visitors interact with our websites.',
      'Web browsers may let users of our website or mobile app disable receiving a certain type of cookies. However, if cookies are deactivated, some features or functionality of our website may not work properly.',
    ],
  },
];

export const termsOfUse = [
  {
    title: '',
    content: [
      'These terms of service (“Terms”) apply to the use of the electronic service Kebeta Invest Limited ("Kebeta.tech", “Kebeta Invest”, “Kebeta Invest Limited”, “Kebeta”, "we," or "us") provides its web site, Kebeta together with all other websites, mobile applications, facebook or telegram message (or communications with us via any other means) we send out, and services operated by or on behalf of Kebeta Invest Limited, the "Site"), to you, an individual user ("you") for your individual usage, subject to compliance with the terms and conditions set forth herein.',
      'By accessing the Site you agree to be bound by these Terms, whether or not you purchase any goods or services offered by Kebeta. These Terms constitute a legally binding contract and you must accept them to use the Site. If you wish to make use of the Site or browse it in any way, please read these Terms of Use. Our Site is meant to provide you information on our various services, relevant information, a means to contact us, and access to our various services.',
      'You should also read our Privacy Policy, which is incorporated by reference into these Terms and available for you to view through the Site. These Terms also include any other terms we disclosed and which you agreed to upon the purchase or acceptance of additional features, goods or services we offer with the Site, such as terms governing discounts or any purchases. Do not use the Site if you do not accept and agree to be bound by all of the terms of these Terms, including our Privacy Policy and other such terms...',
      'You represent and warrant that you are of legal age to form a binding contract. If you are agreeing to these Terms on behalf of an organization or entity, you represent and warrant that you are authorized to agree to these Terms on that organization or entity’s behalf and bind them to these Terms (in which case, the references to “you” and “your” in these Terms, except for in this sentence, refer to that organization or entity).',
      'We reserve the right at any time, with or without cause, to change the terms and conditions of these Terms or change the Site, including eliminating or discontinuing any content, services or other features of our platform. Any changes we make will be effective immediately upon our making such changes available on our website or otherwise providing notice thereof. You agree that your continued use of the Site will constitute your agreement to be bound by the changed Terms.',
      'Please contact us with any questions regarding these Terms.',
    ],
  },
  {
    title: 'USE OF THE SITE',
    content: [
      'You are responsible for:',
      '- making all arrangements necessary for you to have access to the Site;',
      '- ensuring that all persons who access the Site through your internet connection are aware of these Terms and comply with them.',
      'In order to access the Site or some of the resources it offers, you may be required to provide certain registration details, such as your name, phone number or email address. You are solely responsible for any information you provide to us. You represent and warrant that your personal information is true, legal, accurate, non-fraudulent and solely yours. You may not impersonate, imitate or pretend to be somebody else when providing any information on the Site. You agree that all information you provide to register with this Site or otherwise, including but not limited to through the use of any interactive features on the Site, is governed by our Privacy Policy, and you consent to all actions we take with respect to your information consistent with our Privacy Policy.',
    ],
  },
  {
    title: 'THIRD PARTY SERVICES',
    content: [
      'If the Site provides you with an opportunity to engage with third party services, and links to third party websites and services (collectively, “Third Party Services”), these Third Party Services are provided for your convenience only.kebeta does not have any control over these Third Party Services, and accepts no responsibility for them or for any loss or damage that may arise from your use of them.',
      'These Terms do not apply to any Third Party Services and do not govern the practices of the providers of such Third Party Services. When utilizing Third Party Services, you should read the relevant terms of use regarding use of such Third Party Services. If you decide to access any of the Third Party Services available on or linked on this Site, you do so entirely at your own risk and subject to the terms and conditions of use for such Third Party Services.',
    ],
  },
  {
    title: 'PROPRIETARY RIGHTS',
    content: [
      'Kebeta grants you a non-exclusive, non-transferable, non-sublicensable, revocable license to access and use the Site solely under the terms and conditions provided hereunder and or any applicable terms related to you in connection with purchases or selections you make with respect to the Site.',
      'Kebeta and/or its licensors own the Site, including all software and other technology provided or employed by Kebeta in connection with the Site, and the content, design, layout, functions, appearance and other intellectual property comprising or contained or available on the Site, including all copyrights, trademarks, service marks, trade secrets, patents and other intellectual property rights inherent therein or appurtenant thereto. Any authorized use of Kebeta content shall be accompanied by an attribution to Kebeta and a link to the relevant content on our Site.',
      'Kebeta does not grant any licenses to any copyrights, patents, trademarks, trade secrets or other intellectual property rights other than the limited rights to use the Site, as set forth herein and subject to these Terms.',
      'The Site may also contain intellectual property of third parties, including service marks and trademarks. Your use of this Site does not give you any right or license to use such intellectual property, without the prior written permission of the corresponding intellectual property owner.',
      'These Terms permit you to use the Site for your personal, non-commercial use only. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our Site, except as follows:',
      'Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials.',
      'You may store files that are automatically cached by your Web browser for display enhancement purposes. You may print one copy of a reasonable number of pages of the Site for your own personal, non-commercial use and not for further reproduction, publication, or distribution.',
      'If we provide desktop, mobile, or other applications for download, you may download a single copy to your computer or mobile device solely for your own personal, non-commercial use, provided you agree to be bound by our end user license agreement for such applications.',
      'If we provide social media features with certain content, you may take such actions as are enabled by such features. You must not:',
      '- Modify copies of any materials from this Site;',
      '- Use any illustrations, photographs, video or audio sequences, or any graphics separately from the accompanying text;',
      '- Delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from this Site. Access or use for any commercial purposes any part of the Site or any services or materials available through the Site.',
      'If you print, copy, modify, download, or otherwise use or provide any other person with access to any part of the Site in breach of the Terms, your right to use the Site will stop immediately and you must, at our option, return or destroy any copies of the materials you have made.',
      'All rights not expressly granted herein are reserved by Kebeta. Your use of this Site does not grant you ownership rights of any kind in the Site.',
    ],
  },
  {
    title:
      'COPYRIGHT POLICY; NOTICE AND PROCEDURE FOR MAKING CLAIMS OF COPYRIGHT INFRINGEMENT',
    content: [
      'You may not post, distribute, or reproduce in any way any copyrighted material, trademarks, or other proprietary information without obtaining the prior written consent of the owner of such proprietary rights. Without limiting the foregoing, if you believe that your work has been copied and posted on the Site in a way that constitutes copyright infringement, please provide our Copyright Agent with the following information:',
      '- an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest;',
      '- a description of the copyrighted work that you claim has been infringed;',
      '- a description of where the material that you claim is infringing is located on the Site (and such description must be reasonably sufficient to enable the Company to find the alleged infringing material, such as a url);',
      '- your address, telephone number and email address; a written statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;',
      // eslint-disable-next-line quotes
      "- a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf.",
      'Notice of claims of copyright infringement should be provided to the Company’s Copyright Agent to hello@kebeta.tech',
    ],
  },
  {
    title: 'CONFIDENTIALITY',
    content: [
      'As a result of your use of the Site, whether due to any intentional or negligent act or omission, we may disclose to you or you may otherwise learn of or discover our documents, business practices, object code, source code, management styles, day-to-day business operations, capabilities, systems, current and future strategies, marketing information, financial information, software, technologies, processes, procedures, methods, and applications, or other aspects of our business ("Our Information"). You hereby agree and acknowledge that any and all of Our Information is confidential and shall be our sole and exclusive intellectual property and proprietary information. You agree to use Our Information only for the specific purposes as allowed by the performance of these Terms. Any disclosure of Our Information to a third party, specifically including a direct competitor, is strictly prohibited and will be vigorously challenged in a court of law. All obligations contained herein shall survive the termination of these Terms. Furthermore, you acknowledge that Our Information is proprietary, confidential, and extremely valuable to us and that we would be materially damaged by your disclosure of Our Information.',
      'You acknowledge and agree that monetary damages provide an insufficient remedy for the breach of this confidentiality obligation and that we shall be entitled to injunctive relief.',
    ],
  },
  {
    title: 'USER CONDUCT',
    content: [
      'In using the Site, you agree (i) not to send to Kebeta or upload onto or through the Site any information unless you have consent to do so; and (ii) that you will not:',
      '- violate any applicable international, country, province, federal or state laws, regulations or rules (collectively, "Laws") as a result of your use of the Site;',
      '- make any information available to Kebeta if doing so would violate any applicable Laws, industry or professional codes or standards, contractual or fiduciary obligations, confidentiality obligations, or employer policies or other requirements to which you are bound;',
      // eslint-disable-next-line quotes
      `- transmit any information, data, images, or other materials or post any content on the Site that contain vulgar, profane, abusive or hateful language, epithets or slurs, text or illustrations in poor taste, inflammatory attacks of a personal, racial or religious nature, or expressions of bigotry, racism, discrimination or hate, or are unlawful, harmful, threatening, harassing, libelous, defamatory, vulgar, obscene or otherwise objectionable or that may invade another's right of privacy or infringe any intellectual property right, including patent, trademark, service mark, trade secret, copyright or other proprietary rights of any third party;`,
      '- provide any false information to Kebeta;',
      '- impersonate or attempt to impersonate any person or entity or falsely state or otherwise misrepresent your affiliation with a person or entity;',
      '- violate or attempt to violate the security of the Site, including by: testing, scanning, probing or hacking the vulnerability of the Site or any network used by the Site or to breach security, encryption or other authentication measures or attempting to do any of the foregoing; or interfering with the Site by overloading, flooding, pinging, mail bombing or crashing it or attempting to do any of the foregoing;',
      '- reverse engineer, decompile, disassemble or otherwise attempt to reconstruct, identify or discover any source code, underlying ideas, underlying user interface techniques, algorithms or any other aspect or element of any portion of the Site;',
      '- "scrape," copy, transfer, transmit or display any information, data, software, interfaces, code, widgets, tools or other materials contained in or accessible by means of the Site (“Kebeta Information”) from the Site, or use or attempt to use any engine, software, tool, agent or other device or mechanism (including browsers, spiders, bots or robots, avatars or intelligent agents) to navigate or search any portion of the Site, other than through use of the search engine and search agents available from Kebeta on the Site and generally available to third party web browsers (e.g., Mozilla Firefox, Google Chrome, Apple Safari and Microsoft Internet Explorer);',
      '- harvest or collect Kebeta Information, email addresses or other contact information of other users from the Site by electronic or other means for the purposes of sending unsolicited emails or other unsolicited communications;',
      '- copy, mirror or otherwise attempt to replicate or reproduce the Site;',
      '- attack the Website via a denial-of-service attack or a distributed denial-of-service attack;',
      '- otherwise attempt to interfere with the proper working of the Site.',
    ],
  },
  {
    title: 'RELIANCE ON INFORMATION PROVIDED',
    content: [
      'The information presented on or through the Site is made available solely for general information purposes. We do not warrant the accuracy, completeness, or usefulness of this information. Any reliance you place on such information is strictly at your own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials by you or any other visitor to the Site, or by anyone who may be informed of any of its contents.',
      'This Site may include content provided by third parties, including third-party licensors, syndicators, aggregators, and/or reporting services. All statements and/or opinions expressed in these materials, and all articles and responses to questions and other content, are solely the opinions and the responsibility of the person or entity providing those materials. These materials do not necessarily reflect the opinion of Kebeta. We are not responsible, or liable to you or any third party, for the content or accuracy of any materials provided by any third parties.',
      'We may update the content on this Site from time to time, but its content is not necessarily complete or up-to-date. Any of the material on the Site may be out of date at any given time, and we are under no obligation to update such material.',
    ],
  },
  {
    title: 'LINKING TO THE WEBSITE AND SOCIAL MEDIA FEATURES',
    content: [
      'You may link to our homepage, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form of association, approval, or endorsement on our part without our express written consent.',
      'This Site may provide certain social media features that enable you to:',
      '- link from your own or certain third-party websites to certain content on this Site;',
      '- send emails or other communications with certain content, or links to certain content, on this Site;',
      '- cause limited portions of content on this Site to be displayed or appear to be displayed on your own or certain third-party websites;',
      '- you may use these features solely as they are provided by us.',
      'Subject to the foregoing, you must not:',
      '- establish a link from any website that is not owned by you;',
      '- cause the Site or portions of it to be displayed on, or appear to be displayed by, any other site, for example, framing, deep linking, or in-line linking;',
      '- link to any part of the Site other than the homepage;',
      '- otherwise take any action with respect to the materials on this Site that is inconsistent with any other provision of these Terms.',
      'You agree to cooperate with us in causing any unauthorized framing or linking immediately to stop. We reserve the right to withdraw linking permission without notice.',
      'We may disable all or any social media features and any links at any time without notice at our discretion.',
    ],
  },
  {
    title: 'RELEASE OF LIABILITY',
    content: [
      'TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL KEBETA, ITS AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE SITE, ANY SERVICES, ANY SITES LINKED TO IT, ANY CONTENT ON THE SITE OR SUCH OTHER SITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.',
      'THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.',
    ],
  },
  {
    title: 'DISCLAIMER',
    content: [
      'YOUR USE OF THE SITE IS AT YOUR SOLE RISK. THE SITE. ALL THE MATERIALS, INFORMATION, SERVICES, FACILITIES AND OTHER CONTENT AVAILABLE ON THIS SITE ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. YOU ASSUME THE RISK OF ANY AND ALL DAMAGE OR LOSS FROM USE OF, OR INABILITY TO USE, THE SITE. TO THE MAXIMUM EXTENT PERMITTED BY LAW, KEBETA EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED, REGARDING THE SITE, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.',
      'KEBETA DOES NOT WARRANT THAT THE SITE WILL MEET YOUR REQUIREMENTS OR THAT THE OPERATION OF THE SITE WILL BE AVAILABLE CONTINUOUSLY, UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SITE OR THE SERVERS THAT MAKE THE SITE AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. KEBETA DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OR THE RESULTS OF THE USE OF THE MATERIAL, INFORMATION, SOFTWARE, FACILITIES, SERVICES OR OTHER CONTENT ON OR MADE AVAILABLE THROUGH THE SITE OR ANY SITES LINKED TO THIS SITE IN TERMS OF THEIR QUALITY, CORRECTNESS, ACCURACY, RELIABILITY, OR OTHERWISE. KEBETA MAKES NO WARRANTIES THAT YOUR USE OF THE MATERIALS, INFORMATION, SOFTWARE, FACILITIES, SERVICES OR OTHER CONTENT ON THE SITE OR ANY OTHER SITE WILL NOT INFRINGE THE RIGHTS OF OTHERS. KEBETA ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ERRORS OR OMISSIONS IN SUCH MATERIALS, INFORMATION, SOFTWARE, FACILITIES, SERVICES OR OTHER CONTENT ON THIS SITE OR ANY OTHER WEBSITE OR SERVICE. IF APPLICABLE LAW DOES NOT ALLOW THE EXCLUSION OF SOME OR ALL OF THE ABOVE IMPLIED WARRANTIES TO APPLY TO YOU, THE ABOVE EXCLUSIONS WILL APPLY TO YOU ONLY TO THE EXTENT PERMITTED BY APPLICABLE LAW.',
      'kebeta’S PRODUCTS AND SERVICES ARE COMPRISED IN PART OF REPORTS AND RECOMMENDATIONS EMBODYING A UNIQUE COMPLEX OF CONTENT QUALITY MONITORING METRICS. ALL CONTENTS AND RECOMMENDATIONS OF THE SITE ARE BASED ON DATA, SOURCES, AND ALGORITHMS BELIEVED TO BE RELIABLE, BUT ACCURACY AND COMPLETENESS CANNOT BE GUARANTEED. ALL SOFTWARE, SUBSCRIPTION SERVICE, INFORMATION, DATA, REPORTS AND CONTENT PROVIDED BY KEBETA ARE FOR INFORMATIONAL, EDUCATIONAL, AND/OR ENTERTAINMENT PURPOSES ONLY AND ALLOW YOU TO EVALUATE, OR TO MAKE INDEPENDENT DECISIONS CONCERNING.',
    ],
  },
  {
    title: 'SEVERABILITY',
    content: [
      'If any provision of these Terms is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms will continue in full force and effect.',
    ],
  },
  {
    title: 'GOVERNING LAW & JURISDICTION',
    content: [
      'All matters relating to the Site and these Terms and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the internal laws of the State of Delaware without giving effect to any choice or conflict of law provision or rule (whether of the State of Delaware or any other jurisdiction).',
      'Any legal suit, action, or proceeding arising out of, or related to, these Terms or the Site shall be instituted exclusively in the courts of the State of California, county of Kern. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.',
    ],
  },
  {
    title: 'CHANGE IN CONTROL',
    content: [
      'In the event of a change of control of Kebeta or the sale of substantially all of kebeta’s assets or other corporate event, all rights of Kebeta hereunder shall be transferable without notice to you.',
    ],
  },
  {
    title: 'NO INVESTMENT RECOMMENDATIONS OR PROFESSIONAL ADVICE',
    content: [
      'The Site does not provide trading, tax, legal, or investment advice, and nothing on the Site should be construed as an offer to sell, a solicitation of an offer to buy, or a recommendation for any security by Alpha Grail or any third party. You alone are solely responsible for determining whether any investment, security or strategy, or any other product or service, is appropriate or suitable for you based on your investment objectives and personal and financial situation and for evaluating the merits and risks associated with the use of the information on the Site before making any decisions based on such information or other content. You should consult an attorney or tax professional regarding your specific legal or tax situation. AlphaGrail.ai is not a licensed securities dealer, broker or US investment adviser or investment bank.',
    ],
  },
  {
    title: 'CONTACT',
    content: [
      'If you have any questions about these Terms, you may contact us at hello@kebeta.tech.',
    ],
  },
];
